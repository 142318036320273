import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import Fraction from '../../images/icons/fraction.inline.svg';
import HouseExpenses from '../../images/icons/house-expenses.inline.svg';
import Keys from '../../images/icons/keys.inline.svg';
import Coconut from '../../images/icons/coconut.inline.svg';
import H from "../text/H";
import P from "../text/P";
import { breakPoints } from "../../misc/consts";

const Container = styled.div`
  position: relative;

  .bg-img {
    width: 0;
    max-height: 500px;

    ${breakPoints.tablet} {
      width: 85%;
      left: -15%;
    }
    ${breakPoints.desktop} {
      width: 62%;
      left: 0;
    }
    ${breakPoints.largeDesktop} {
      width: 60%;
    }
  }

  .cards {
    gap: 20px;
    position: relative;
    right: 0;
    width: 100%;

    ${breakPoints.tablet} {
      position: absolute;
      width: 65%;
    }
    ${breakPoints.desktop} {
      width: 60%;
    }
    ${breakPoints.largeDesktop} {
      width: 45%;
    }
  }

  ${breakPoints.desktop} {

  }
`;

function BenefitsSection() {
  return (
    <Container className="center white-bg">
      <div className="marginal">
      <H className="fs-5 center-self">Beneficios</H>
      <P className="fs-3 mb-lg center-self center-text">Comprar una fracción es mucho más que solo ser dueño</P>
      <div className="h-stack" style={{ position: 'relative', alignItems: 'center' }}>
        <StaticImage
          src="../../images/corporate/guy-sitting.png"
          alt="guy sitting"
          quality={100}
          className="bg-img"
        />
        <div className="cards">
          <div className="shadow-1 pd-lg h-stack white-bg">
            <Fraction className="mr-sm" />
            <div>
              <H className="fs-3">Comprar solo lo que utlizas</H>
              <P className="fs-2">
                ¿Por qué comprar una casa completa si no la utilizas todo el año? Con Ancana, la compra fraccional te permite tener solo la parte que necesitas.
              </P>
            </div>
          </div>
          <div className="shadow-1 pd-lg h-stack white-bg">
            <HouseExpenses className="mr-sm" />
            <div>
              <H className="fs-3">Divide los gastos</H>
              <P className="fs-2">
                En lugar de asumir todos los gastos, Ancana distribuye equitativamente los costos entre los co-dueños, haciendo la experiencia más accesible para todos.
              </P>
            </div>
          </div>
          <div className="shadow-1 pd-lg h-stack white-bg">
            <Coconut className="mr-sm" />
            <div>
              <H className="fs-3">Tranquilidad</H>
              <P className="fs-2">
                Desde el diseño de interiores hasta el mantenimiento, Ancana se encarga de todo para que puedas concentrarte en disfrutar tu tiempo de vacaciones sin preocupaciones.
              </P>
            </div>
          </div>
          <div className="shadow-1 pd-lg h-stack white-bg">
            <Keys className="mr-sm" />
            <div>
              <H className="fs-3">Reservas flexibles</H>
              <P className="fs-2">
                Personaliza tu experiencia con la propiedad, ya sea compartiéndola con tu familia, generando ingresos al alquilarla en semanas no utilizadas, o intercambiándola con más de 15,000 propiedades en todo el mundo. Tu flexibilidad, tu elección.
              </P>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Container>
  )
}

export default BenefitsSection;
