import './i18n';
import '../components/layout.css';

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Script } from "gatsby";

import {
  HeroBannerSection,
  PropertiesSection,
  LoremSection,
  FractionalSection,
  BenefitsSection,
  HowSection,
  WhyAncanaSection
} from "../components/sectionsV3";
import ContactSection from "../components/sectionsV3/ContactSection";
import FaqSection from "../components/sectionsV3/FaqSection";
import Footer from "../components/sectionsV3/Footer";
import GallerySection from "../components/sectionsV3/GallerySection";
import MentionsSection from "../components/sectionsV3/MentionsSection";
import NavBar from "../components/sectionsV3/NavBar";
import { scrollToSection } from "../misc/utils";
import { Helmet } from "react-helmet";
import { adminApi } from "../api/ancanaAPI";
import ContactModal from "../components/ContactModal";
import useObserveRef from "../hooks/useObserveRef";


const Container = styled.div`
  overflow-x: hidden;
  // background-color: #FDFBF7;

  .ancana-logo {
    width: 138px;
  }
`;

const mockedListings = [
  {
    "id": 35,
    "name": "Vail 2A",
    "slug": "vail-141",
    "amenities": [
      6,
      10,
      28,
      29,
      33,
      48,
      54
    ],
    "images": [
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Exterior_1/e0e31af71754a641354343de43cb5057.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Interior_2/ff9e71ec7bd9d3372aea74269e9b656a.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Interior_3/3be68599fb0ca5c9db13f34ef58d9224.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Interior_1/6ab9ad5c8cbc4037681c3e075559f4b2.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/AMS_VB_Solaris_Unit2ASouth_4/ee031364e2d004fddfc8fe945a7ecefe.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Plaza_Solaris/d0609b3346739850d03fcb8def3dbbd6.webp",
      "https://api-v1.s3.amazonaws.com/CACHE/images/images/Exterior_2/1ee7c3a6db80e83e553b4f945ace6ae2.webp"
    ],
    "files": [],
    "lat": "39.642777",
    "lng": "-106.375570",
    "address": "141 E Meadow Dr, Vail, CO 81657, USA",
    "type": "unit",
    "published": true,
    "status": "new_arrival",
    "location": 12,
    "price": {
      "amount": 1111000.0,
      "currency": "USD"
    },
    "characteristics": {
      "square_meters": 260.0,
      "bedrooms": 3.0,
      "bathrooms": 4.0,
      "fractions": 8,
      "weeks_available": 6
    },
    "description_en": "Located in the prestigious Solaris complex in Vail, this iconic property embodies refined mountain living. From this elevated vantage point, one can admire the picturesque village of Vail, majestic mountain peaks, and the renowned Solaris Plaza a true landmark in this exceptional destination.\n\nThis residence is a reflection of Vail's refined style. With its generous wraparound terrace, unparalleled views of the village and mountains, and proximity to the celebrated Solaris Plaza, it sets a new standard of elegance. \n\nLocated within the resort but independently, The Heart of Vail is situated in a semi-private building with a separate entrance from the street and underground parking. This allows guests to truly enjoy a close and exclusive experience in the heart of everything",
    "description_es": "Ubicada en el prestigioso complejo Solaris en Vail, esta propiedad icónica personifica la vida refinada en la montaña. Desde este punto elevado, puedes admirar el pintoresco pueblo de Vail, las majestuosas cumbres montañosas y la célebre Plaza Solaris, un verdadero hito en este destino excepcional.\n\nEsta residencia es un reflejo del estilo refinado de Vail. Con su generosa terraza envolvente, vistas incomparables del pueblo y las montañas, y su cercanía a la celebrada Plaza Solaris, establece un nuevo estándar de elegancia.\n\nUbicado dentro del complejo turístico pero de forma independiente, The Heart of Vail se sitúa en un edificio semiprivado con una entrada separada desde la calle y el estacionamiento subterráneo. Esto permite a los huéspedes disfrutar verdaderamente de una experiencia cercana y exclusiva en el corazón de todo.",
    "unit": 40,
    "design": null,
    "development": null,
    "cover_image": "https://api-v1.s3.amazonaws.com/CACHE/images/images/Exterior_1/4cde6d6a6b00f422b0e2d202df34c663.webp",
    "sort_index": 1
  }
];

const V2Page = ({ pageContext }) => {
  const [listings, setListings] = useState(mockedListings);
  const containerRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [alreadyPopedup, setAlreadyPopedup] = useState(false);
  const alreadyPopedupRef = useRef(alreadyPopedup);

  const openPopup = () => {
    if(!alreadyPopedupRef.current) {
      setPopupVisible(true);
      // alreadyPopedupRef.current = true;
      setAlreadyPopedup(true);
    }
  }

  const { observedRef } = useObserveRef(alreadyPopedup, openPopup);

  const scrollTo = (name, offset = 0, cancelPopup = true) => {
    if (cancelPopup) setAlreadyPopedup(true);
    scrollToSection(name, containerRef, offset);
  }

  useEffect(() => {
    adminApi.get('houses/previews/?page_size=8&published=true&location_slug=vail').then(res => {
      setListings(res.data.results)
    });
    const timeout = setTimeout(() => openPopup(), 15000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    alreadyPopedupRef.current = alreadyPopedup;
  }, [alreadyPopedup]);

  return (
    <ParallaxProvider>
      <Container ref={containerRef}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ancana - Vail</title>
          <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>
        <NavBar scrollTo={scrollTo} />

        <div id="hero">
          <HeroBannerSection scrollTo={scrollTo} />
        </div>
        <div id="property">
          <PropertiesSection listings={listings} scrollTo={scrollTo} />
        </div>
        <LoremSection />
        <div id="fractional">
          <FractionalSection />
        </div>
          <BenefitsSection />
        <div ref={observedRef}>
         <HowSection />
        </div>
        <WhyAncanaSection />
        <div id="contact">
          <ContactSection />
        </div>

        <GallerySection houses={listings} />
        <MentionsSection />

        <FaqSection />

        <Footer scrollTo={(name) => scrollToSection(name, containerRef)} />
      </Container>

      <ContactModal
        visible={popupVisible}
        handleClose={() => setPopupVisible(false)}
      />
    </ParallaxProvider>
  );
}

export default V2Page;
