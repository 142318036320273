import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import P from "../text/P";
import H from "../text/H";
import House from '../../images/icons/house1.inline.svg'
import Calendar from '../../images/icons/calendar.inline.svg';
import Keys from '../../images/icons/keys2.inline.svg';
import B from "../text/B";
import { breakPoints } from "../../misc/consts";

const Container = styled.div`
  position: relative;
  z-index: 0;

  flex-direction: column-reverse;
  ${breakPoints.tablet} {
    flex-direction: row;
    align-items: center;
  }

  .lorem-img {
    width: calc(100% + 32px);
    margin: -24px -16px 0 -16px;
    ${breakPoints.tablet} {
      width: 75%;
      margin: 0 0 0 25%;
      height: 560px;
    }
    ${breakPoints.desktop} {
      width: 70%;
      margin-left: 30%;
      height: 580px;
    }
    ${breakPoints.largeDesktop} {
      width: 60%;
      margin-left: 40%;
      height: 560px;
    }
  }

  .perk {
    text-align: center;
    ${breakPoints.tablet} {
      text-align: left;
    }
  }
`;

const DescriptionCard = styled.div`
  width: 100%;
  flex: 1;
  position: relative;

  ${breakPoints.tablet} {
    position: absolute;
    width: 60%;
  }
  ${breakPoints.desktop} {
    width: 55%;
  }
  ${breakPoints.largeDesktop} {
    width: 50%;
  }
  padding: 18px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
`;

function LoremSection() {
  return (
    <Container className="marginal">
      <div style={{ flex: 3, zIndex: -3 }}>
        <StaticImage
          src="../../images/corporate/guy-reading.jpg"
          alt="lorem"
          quality={50}
          className="lorem-img"
        />
      </div>
      <DescriptionCard className="corp-shadow-lg" style={{ flex: 4 }}>
        <H className="fs-6 mb-md">¿En búsqueda de una casa vacacional?</H>
        <P className="fs-2-66">
          Comprendemos que encontrar la casa vacacional ideal puede ser un desafío. Desde la elección de la propiedad perfecta hasta garantizar que todo esté impecable cuando decidas visitarla.
        </P>
        <P className="fs-2-66 mt-md">
          Usualmente los problemas de adquirir una casa vacacional por tu cuenta se resumen en lo siguiente:
        </P>
        <div className="h-stack center pd-md">
          <Calendar className="mr-md" />
          <B className="perk fs-2-66">
            No la usarás todo el año
          </B>
        </div>
        <div className="h-stack center pd-md">
          <House className="mr-sm" />
          <B className="perk fs-2-66">
            Tendrás que amueblarla por cuenta propia
          </B>
        </div>
        <div className="h-stack center pd-md">
          <Keys className="mr-md" />
          <B className="perk fs-2-66">
            Administración, gestión y mantenimiento de la propiedad no incluidos
          </B>
        </div>
        <P className="fs-2-66">
          ¿Que tal si hubiera una manera en la que pudieras tener todos los beneficios de una casa vacacional sin los dolores de cabeza de mantenerla y administrarla por tu cuenta y a distancia?
        </P>
      </DescriptionCard>
    </Container>
  )
}

export default LoremSection;
